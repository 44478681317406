<template>
<div class="forgot-container">
    <TourialLogo />
    <div style="width: 100%;">
        <h3 class="bold">{{ $t('forgot.title') }}</h3>
        <p>{{ $t('forgot.caption') }}</p>
    </div>

    <form>
        <vs-input block :placeholder="$t('common.email')" type="email" v-model="email" />
    </form>
    <p style="width: 100%; cursor: pointer;" @click="$router.push({name:'Accedi'})">
        {{ $t('forgot.back') }}
    </p>

    <vs-button size="large" block class="bold" @click="forgotEvent" :loading="loading">{{ $t('common.continue') }}</vs-button>

    <p class="center">
        {{ $t('login.dontHaveAccount') }}<br />
        <router-link to="/explore/welcome">{{ $t('login.signUp') }}</router-link>
    </p>

</div>
</template>

    
<script>
import TourialLogo from '../TourialLogo.vue';
import {
    apiCall
} from '../../utils/ApiMiddleware';
export default {
    name: 'forgot',
    props: {
        iEmail: String,
    },
    data() {
        return {
            email: '',
            loading: false
        }
    },
    methods: {
        async forgotEvent() {
            this.loading = true;
            await this.forgot();
            this.loading = false;
        },

        async forgot() {
            // if email field is empty, show an error message
            if (this.email == '') {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('login.messages.emailEmpty'),
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // use apiCall to call the login api
            const response = await apiCall('POST', '/users/recover/init', {
                email: this.email,
            });
            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('forgot.messages.passwordResetSent'),
                    color: 'success',
                    position: 'top-right'
                });
            } else if (response.status != 0) { // 0 is an already handled error, no needs to show another error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('forgot.messages.cannotRecover'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        }
    },
    mounted() {
        //this.email = this.iEmail;
    },
    components: {
        TourialLogo
    }
}
</script>

    
<style scoped>
.forgot-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

/* DESKTOP */
@media (min-width: 1024px) {
    .forgot-container {
        width: 350px;
    }
}

/* MOBILE */
@media (max-width: 600px) {
    .forgot-container {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        width: 100% !important;

    }
}
</style>
